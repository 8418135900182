(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

var ScrollAction = function ScrollAction(args) {
  var that = this;

  this.wrapper = args.wrapper;
  this.offset = args.offset ? args.offset : 0;
  this.classToAdd = args.classToAdd;
  this.init = function () {
    this.cacheDom();
    bindEvents();
  };
  this.cacheDom = function () {
    this.el = document.querySelector('header');
    console.log(this.wrapper);
  };
  function bindEvents() {
    window.addEventListener('scroll', decideAction);
    window.addEventListener('load', decideAction);
  }

  function decideAction() {
    if (typeof that.offset === "number") {
      makeAction(that.offset);
    } else if (typeof that.offset === "string") {
      var hookMoment = document.querySelector(that.offset);
      makeAction(document.querySelector(that.offset).offsetTop);
    }
  }

  function makeAction(argument) {
    var scrollTop = window.pageYOffset;
    if (scrollTop > argument) {
      document.querySelector(that.wrapper).classList.add(that.classToAdd);
    } else {
      document.querySelector(that.wrapper).classList.remove(that.classToAdd);
    }
  }
};
module.exports = ScrollAction;

},{}],2:[function(require,module,exports){
"use strict";

var _scrollAction = require("./modules/scroll-action.js");

var _scrollAction2 = _interopRequireDefault(_scrollAction);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var scrollAction = new _scrollAction2.default({
  wrapper: 'header', // element which get class - 'classToAdd' after reaching offset
  offset: 1, // number || div, that points the offset from top
  classToAdd: "is-sticky"
});
scrollAction.init();

window.NavShow = function () {
  var element = document.querySelector(".navigation");
  element.classList.toggle("is-visible");
};

var test = document.querySelector(".navigation");
console.log(test);

function WasClicked() {
  //var item = document.querySelector(".navigation");
  this.classList.add("is-clicked");
}

// Funkcja dodająca obserwatora zdarzeń dla tabeli t
(function WaitForClick() {
  var el = document.querySelector(".navigation");
  el.addEventListener("click", WasClicked, true);
})();

},{"./modules/scroll-action.js":1}]},{},[2]);
